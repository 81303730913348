import Alert from "@mui/material/Alert";
import { Box, Button as ButtonMui, Container, Grid, IconButton, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import { main_blue, main_gray } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input from "../../component/input/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FORGET_PASSWORD_URL } from "../../config/RoutesUrl";
import Button from "../../component/buttons/Button";
// import ButtonSmall from "../../component/buttons/ButtonSmal";
import useIsAuthenticate from "../../hooks/useIsAuthenticate";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticate from "../../hooks/useAuthenticate";
import { useEffect, useState } from "react";
import { setAuth } from "../../store/AuthenticationReducer";
import { setAlert } from "../../store/SignalementReducer";
import KjosAlert from "../../component/KjosAlert";
import { RESET_PASSWORD_PATH, SIGNUP_PATH } from "config/PathUrl";
import { Home } from "@mui/icons-material";
import useResetPassword from "hooks/useResetPassword";
import { login_image } from "config/Image";

export default function SignIn() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { auth, error } = useSelector((state) => state.authentication);
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { signInConfirm } = useResetPassword();
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setAuth({}));
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, [dispatch]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <KjosAlert label={alert.message} />

      <form onSubmit={signInConfirm} style={{ height: "100%" }}>
        {location.state?.nextPage && <KjosAlert label={alert.message} />}
        {isSmallScreen && (
          <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={{ xs: 3, md: 6 }} sx={{ height: { md: "100%", width: "100%" } }}>
            <Logo />
            <Box sx={{ marginY: { md: "auto" } }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: {
                    xs: "1rem",
                    color: main_blue,
                  },
                  textAlign: "center",
                }}
              >
                Bienvenue dans la version web de ANSSI
              </Typography>
              <Typography variant="h6" sx={{ fontSize: { xs: "0.8rem" } }}>
                Vous souhaitez signaler un contenu illicite de l’internet ?
              </Typography>
            </Box>
          </Stack>
        )}
        <Grid container sx={{ height: "100%" }}>
          {isMediumScreen && (
            <Grid item xs={12} md={6} sx={{ height: { md: "100%" }, p: 0 }}>
              <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"} sx={{ height: { md: "100%" }, overflow: "hidden", bgcolor: main_gray }}>
                {/* <Logo /> */}
                {/* <img  src={login_image} style={{ width: "100%", height: "auto", objectFit: "contain" }} /> */}
              </Stack>
            </Grid>
          )}

          <Grid item xs={12} md={6} sx={{ height: { md: "100%" } }}>
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={6} sx={{ height: { md: "100%" } }}>
              <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} alignItems={"center"} sx={{ width: "100%" }}>
                <ButtonMui
                  startIcon={<Home />}
                  sx={{ width: "40px", height: "40px", me: "auto", textTransform: "none" }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  {" "}
                  Accueil{" "}
                </ButtonMui>

                {/* <Typography variant="body1"> Nouveau sur ANSSI? </Typography>
                <ButtonMui className="rectangle-3" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => {navigate(SIGNUP_PATH)}}>
                Inscription
              </ButtonMui> */}
                {/* <button className="rectangle-3"> Inscription </button> */}
              </Stack>
              <Box sx={{ marginY: { md: "auto" } }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: {
                      xs: "2rem",
                      sm: "3rem",
                      md: "4rem",
                      color: main_blue,
                    },
                  }}
                >
                  Connectez-vous
                </Typography>
                <Typography variant="h6" sx={{ color: main_blue }}>
                  Renseignez votre numéro de téléphone ou votre email
                </Typography>

                <br />
                <input type="hidden" name="makeSignalement" value={location.state?.makeSignalement} />
                <Input
                  emailPhone={true}
                  placeholder="0000000000"
                  label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                        },
                      }}
                    >
                      Email ou téléphone
                    </Typography>
                  }
                  type="text"
                  width="100%"
                  // error={error && auth?.email && error}
                  name="email"
                  nameCode="countryCode"
                />

                <br />

                {/* <Input
                  placeholder="*************"
                  label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                        },
                      }}
                    >
                      Mot de passe
                    </Typography>
                  }
                  type="password"
                  width="100%"
                  name="password"
                  // error={error && auth?.password && error}
                /> */}
                <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} py={2}>
                  {/* <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography variant="body1" sx={{ color: main_blue }}>
                      Se souvenir de moi
                    </Typography>
                    <Switch />
                  </Stack> */}

                  {/* <Link to={RESET_PASSWORD_PATH} style={{ textDecoration: "none" }}>
                    <Typography variant="body1" sx={{ color: "red" }}>
                      Mot de passe oublié
                    </Typography>
                  </Link> */}
                </Stack>
              </Box>

              {/* <ButtonSmall title="Suivant" sx={{alignSelf:"flex-start"}} type="submit" /> */}
              <div className="d-flex flex-row align-items-center justify-content-center gap-2">
                <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit" onClick={() => setLoading(true)}>
                  Suivant
                </ButtonMui>
                {loading && (
                  <div className="spinner-border" role="status" style={{ color: main_blue }}>
                    <span className="sr-only"></span>
                  </div>
                )}
              </div>

              {/* Copyright */}
              <Typography variant="body1" sx={{ color: main_blue, marginTop: "auto", textAlign: "center" }}>
                Copyright @ ANSSI 2023
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
