import { Box, Button as ButtonMui, Container, Grid, Stack, Typography } from "@mui/material";
import { bg_black, main_blue2 } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input from "../../component/input/Input2";
import Select from "../../component/input/Select";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_PLAINTE_URL, USER_SOURCE_URL, USER_TYPE_SIGNALEMENT_URL } from "../../config/RoutesUrl";
import useHttp from "../../hooks/useHttp";
import HeaderConnecte from "component/header/HeaderConnecte";
import KjosHeader from "component/KjosHeader";
import Footer from "component/footer/Footer";
import Rectangle from "component/rectangle/Rectangle";
import { PRE_PLAINTE_FIN_PATH } from "config/PathUrl";
import InputDate from "component/input/InputDate";
import CheckBox from "component/input/CheckBox";
import TextArea from "component/textarea/TextArea";
import KjosNextPage from "component/KjosNextPage";
import useUser from "hooks/useUser";

export default function PrePlainteServiceFaites() {
  const navigate = useNavigate();
  const location = useLocation();
  const { postData } = useHttp();
  const { getUser } = useUser();

  const handleForward = async (e) => {
    e.preventDefault();
    const stateData = JSON.parse(location.state?.data);
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);

    const data2 = {
      ...data,
      ...stateData,
      user_id: getUser("user")?.user?.id,
    };
    const res = await postData(data2, USER_PLAINTE_URL)
    navigate(PRE_PLAINTE_FIN_PATH, { state: {code: res?.data.code, nextPage: true } });
  };

  return (
    <KjosNextPage>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
      >
                <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>

          <KjosHeader>Créer un signalement | ANSSI</KjosHeader>

          <HeaderConnecte />
          <form onSubmit={handleForward} style={{ width: "100%", height: "100%" }}>
          {location?.state?.signalement_id && (
              <Typography variant="body2" sx={{ textAlign: "center", background: "linear-gradient(95.08deg, rgba(33, 39, 89, 1) 0%, rgba(74, 144, 226, 1) 100%)", font: "800 24px sans-serif", textDecoration: "underline", "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent" }}>
                Signalement N°: {location?.state?.signalement_id}
              </Typography>
            )}
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ height: "100%", width: "100%", px: { xs: 1, md: 8 },py:8 }} spacing={4}>
              <Typography variant="h5" sx={{ textAlign: "start", color: main_blue2, fontWeight: "bold" }}>
              Terminer le processus de pré plainte 
              </Typography>

              <Grid container spacing={2} px={2}>
                <Grid item xs={12} md={2}></Grid>
                <Grid item xs={12} md={8}>
                  <Stack direction={{ xs: "column", md: "row" }} alignItems={{ xs: "flex-start", md: "center" }} justifyContent={{ xs: "center", md: "flex-start" }} spacing={2} sx={{ width: "100%" }}>
                    <InputDate label="Date des faits" name="date_faits" max={new Date().toISOString().split("T")[0]} style={{ width: "100%" }} />
                    <Input label="Objet de la plainte" type="text" name="objet_plainte" width="100%" /> <br />
                  </Stack>
                  <br />
                  <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} spacing={2} sx={{ width: "100%" }}>
                    <Input label="Lieu des faits" type="text" name="lieu_faits" width="100%" /> <br />
                    <Input label="Personne mise en cause" type="text" name="personne_mise_cause" width="100%" /> <br />
                  </Stack>
                  <br />
                  <Rectangle
                    label="Les faits et circonstances"
                    content={
                      <Stack direction={"column"} my={2} spacing={1} sx={{ width: "100%" }}>
                        <Typography variant="h5" sx={{ textAlign: "center", color: "black", fontWeight: "bold" }}>
                          Quels sont les faits dont vous avez été victime ?
                        </Typography>
                        <TextArea name="faits_et_circonstances" width="100%" border="none" />
                      </Stack>
                    }
                  />{" "}
                  <br />
                  <TextArea label="Faite une description des faits" name="description_faits" width="100%" /> <br />
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>
                    <CheckBox name="condition_utilisation" id="condition_utilisation" value="1" required />
                    <Typography variant="body1" sx={{ textAlign: "start", color: "black" }}>
                      Je certifie que les informations que j’ai fournis en remplissant se formulaire de plainte en ligne engage ma personne.{" "}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2}></Grid>
              </Grid>

              <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}  spacing={2}>
                <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)} >
                  Précédent
                </ButtonMui>

                <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                Suivant
              </ButtonMui>
                </Stack>
            </Stack>
          </form>
        </Stack>
      </Box>
      <Footer fixed={false} />
    </KjosNextPage>
  );
}
