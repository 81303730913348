import React from "react";
import ReactDOM from "react-dom/client";
import "./style/bootstrap.scss";
import "./style/style.scss";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Error from "./template/Error";
import SignUp from "pages/auth/SignUp";
import SignupInformation from "pages/auth/SignupInformation";
import SignInPasswordForget from "pages/auth/SignInPasswordForget";
import SigninPasswordForgetConfirm from "pages/auth/SigninPasswordForgetConfirm";
import SigninPasswordNew from "pages/auth/SigninPasswordNew";
import SignupPassword from "pages/auth/SignupPassword";
import SignUpCodeConfirm from "pages/auth/SignUpCodeConfirm";
import SignInCodeConfirm from "pages/auth/SignInCodeConfirm";
import ResetPassword from "./template/ResetPassword";
import Recherche from "./template/Recherche";
import ContenuAnonyme from "./template/ContenuAnonyme";
import Contenu from "./template/Contenu";
import Description from "./template/Description";
import SignalementList from "./template/SignalementList";
import SignalementListTraite from "./template/SignalementListTraite";
import SignalementListEncour from "./template/SignalementListEncour";
import Setting from "./template/Setting";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ARTICLE_DETAIL_PATH, ASSISTANCE_ANONYME_PATH, ASSISTANCE_PATH, CONDITION_UTILISATION_PATH, CONTENU_ANONYME_PATH, CONTENU_PATH, DASHBOARD_PATH, DESCRIPTION_PATH, DESCRIPTION_PATH_ANONYME, POLITIQUE_PATH, PRE_PLAINTE_DETAIL_PATH, PRE_PLAINTE_FAITS_PATH, PRE_PLAINTE_FIN_PATH, PRE_PLAINTE_IDENTITE_PATH, PRE_PLAINTE_SERVICE_PATH, PROFIL_EDIT_PATH, PROFIL_PATH, RECHERCHE_ANONYME_PATH, RECHERCHE_PATH, RESET_PASSWORD_CONFIRM_CODE_PATH, RESET_PASSWORD_CONFIRM_PATH, RESET_PASSWORD_PATH, SETTING_PATH, SIGNALEMENT, SIGNALEMENT_ANONYME, SIGNALEMENT_CREATE_FIN_PATH, SIGNALEMENT_CREATE_PATH, SIGNALEMENT_CREATE_SUIVANT_PATH, SIGNALEMENT_CREE, SIGNALEMENT_CREE_ANONYME, SIGNALEMENT_LIST_ENCOUR_PATH, SIGNALEMENT_LIST_PATH, SIGNALEMENT_LIST_TRAITE_PATH, SIGNALEMENT_PRE_PLAINTE_PATH, SIGNALEMENT_SUIVIE_PATH, SIGNIN_CONFIRM_PATH, SIGNIN_PATH, SIGNUP_CONFIRM_PATH, SIGNUP_INFORMATION_PATH, SIGNUP_PASSWORD_PATH, SIGNUP_PATH } from "./config/PathUrl";
import IsAuthUser from "./component/IsAuthUser";
import SignalementCree from "./template/SignalementCree";
import ResetPasswordConfirm from "./template/ResetPasswordConfirm";
import ResetPasswordWebConfirmCode from "./plateform/ResetPasswordWebConfirmCode";
import SignalementDetail from "pages/signalements/layouts/SignalementDetail";
import PrePlainteDetail from "pages/signalements/layouts/PrePlainteDetail";
import Profil from "./pages/profile/Profil";
import DescriptionWebAnonyme from "./plateform/DescriptionWebAnonyme";
import Assistance from "./template/Assistance";
import ProfilEdit from "./pages/profile/ProfilEdit";
import RechercheAnonyme from "./template/RechercheAnonyme";
import SignalementCreeAnonyme from "./template/SignalementCreeAnonyme";
import SignalementAnonyme from "./template/SignalementAnonyme";
import AssistanceAnonyme from "./template/AssistanceAnonyme";
import SignIn from "./pages/auth/SignIn";
import { ThemeProvider } from "@mui/material";
import { roboto } from "./config/Theme";
import Accueil from "pages";
import Dashboard from "pages/dashboard/Dashboard";
import CreateSignalement from "pages/signalements/CreateSignalement";
import CreateFinSignalement from "pages/signalements/CreateFinSignalement";
import CreateSuivantSignalement from "pages/signalements/CreateSuivantSignalement";
import PrePlainteSignalement from "pages/signalements/PrePlainteSignalement";
import SuivieSignalement from "pages/signalements/SuivieSignalement";
import PrePlainteIdentite from "pages/signalements/PrePlainteIdentite";
import PrePlainteServiceFaites from "pages/signalements/PrePlainteServiceFaites";
import PrePlainteFin from "pages/signalements/PrePlainteFin";
import PrePlainteService from "pages/signalements/PrePlainteService";
import PolitiqueConfidentialite from "pages/politique/PolitiqueConfidentialite";
import ConditionGeneral from "pages/politique/ConditionGeneral";
import DetailArticle from "component/articles/DetailArticle";

const router = createBrowserRouter([
  {
    exact: true,
    path: "/",
    element: <IsAuthUser component={<Accueil />} />,
    // element: <Accueil />,
    error: <Error />,
  },
  {
    path: SIGNIN_PATH,
    // element: <IsAuthUser component={<SignIn />} />,
    element: <SignIn />,
    error: <Error />,
  },
  {
    path: SIGNUP_PATH,
    element: <IsAuthUser component={<SignUp />} />,
    error: <Error />,
  },
  {
    path: SIGNUP_INFORMATION_PATH,
    element: <IsAuthUser component={<SignupInformation />} />,
    error: <Error />,
  },
  {
    path: SIGNUP_PASSWORD_PATH,
    element: <IsAuthUser component={<SignupPassword />} />,
    error: <Error />,
  },
  {
    path: SIGNUP_CONFIRM_PATH,
    element: <IsAuthUser component={<SignUpCodeConfirm />} />,
    error: <Error />,
  },
  {
    path: SIGNIN_CONFIRM_PATH,
    element: <IsAuthUser component={<SignInCodeConfirm />} />,
    error: <Error />,
  },
  {
    path: RESET_PASSWORD_PATH,
    element: <IsAuthUser component={<SignInPasswordForget />} />,
    error: <Error />,
  },
  {
    path: RESET_PASSWORD_CONFIRM_CODE_PATH,
    element: <IsAuthUser component={<SigninPasswordForgetConfirm />} />,
    error: <Error />,
  },
  {
    path: RESET_PASSWORD_CONFIRM_PATH,
    element: <IsAuthUser component={<SigninPasswordNew />} />,
    error: <Error />,
  },
  {
    path: PROFIL_PATH,
    element: <IsAuthUser component={<Profil />} />,
    error: <Error />,
  },
  {
    path: PROFIL_EDIT_PATH,
    element: <IsAuthUser component={<ProfilEdit />} />,
    error: <Error />,
  },

  {
    path: SIGNALEMENT,
    element: <IsAuthUser component={<SignalementDetail />} />,
    error: <Error />,
  },

  {
    path: ASSISTANCE_PATH,
    element: <IsAuthUser component={<Assistance />} />,
    // element: <Assistance />,
    error: <Error />,
  },
  {
    path: ASSISTANCE_ANONYME_PATH,
    // element: <IsAuthUser component={<Contenu />} />,
    element: <AssistanceAnonyme />,
    error: <Error />,
  },
  {
    path: DASHBOARD_PATH,
    // element: <SignalementList />,
    element: <IsAuthUser component={<Dashboard />} />,
    // element: <Dashboard />,
    error: <Error />,
  },

  {
    path: SIGNALEMENT_CREATE_PATH,
    element: <IsAuthUser component={<CreateSignalement />} />,
    error: <Error />,
  },

  {
    path: SIGNALEMENT_SUIVIE_PATH,
    element: <IsAuthUser component={<SuivieSignalement />} />,
    error: <Error />,
  },
  {
    path: SIGNALEMENT_PRE_PLAINTE_PATH,
    element: <IsAuthUser component={<PrePlainteSignalement />} />,
    error: <Error />,
  },
  {
    path: SIGNALEMENT_CREATE_SUIVANT_PATH,
    element: <IsAuthUser component={<CreateSuivantSignalement />} />,
    error: <Error />,
  },
  {
    path: SIGNALEMENT_CREATE_FIN_PATH,
    element: <IsAuthUser component={<CreateFinSignalement />} />,
    error: <Error />,
  },

  {
    path: PRE_PLAINTE_SERVICE_PATH,
    element: <IsAuthUser component={<PrePlainteService />} />,
    error: <Error />,
  },


  {
    path: PRE_PLAINTE_IDENTITE_PATH,
    element: <IsAuthUser component={<PrePlainteIdentite />} />,
    error: <Error />,
  },


  {
    path: PRE_PLAINTE_FAITS_PATH,
    element: <IsAuthUser component={<PrePlainteServiceFaites />} />,
    error: <Error />,
  },


  {
    path: PRE_PLAINTE_FIN_PATH,
    element: <IsAuthUser component={<PrePlainteFin />} />,
    error: <Error />,
  },

  {
    path: PRE_PLAINTE_DETAIL_PATH,
    element: <IsAuthUser component={<PrePlainteDetail />} />,
    error: <Error />,
  },

  {
    path: POLITIQUE_PATH,
    element: <PolitiqueConfidentialite />,
    error: <Error />,
  },

  {
    path: CONDITION_UTILISATION_PATH,
    element: <ConditionGeneral />,
    error: <Error />,
  },

  {
    path: ARTICLE_DETAIL_PATH,
    element: <DetailArticle />,
    error: <Error />,
  },

]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={roboto}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </Provider>
);
