import {  Stack, Typography } from "@mui/material";
import "./input.css";


export default function CheckRadio(prop) {
  const { width = "100%", type, required, value, defaultValue, onChange,  disabled, style, label, error, name, id, inputRef, options, key, hidden, index } = prop;

  return (
    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2} key={key} >
      <div className="radio-2" style={{ ...style }}>
      <input  type={"radio"} required={required} value={value} defaultValue={defaultValue} onChange={onChange} disabled={disabled} className={"_00-00-00-00-00-2"} name={name} id={id} hidden={hidden} ref={inputRef} />
        
        <div className="frame-1000002030-2">
        </div>
      </div>
      <label className="email-ou-num-ro-de-t-l-phone-2" htmlFor={id} style={{cursor: "pointer"}}> {label} </label>

      <Typography variant="body1" sx={{ color: "red" }}>
        {" "}
        {error}{" "}
      </Typography>
    </Stack>
  );
}
