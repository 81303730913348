import { Box, Button as ButtonMui, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { bg_black, main_blue, main_blue2 } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input from "../../component/input/Input2";
// import Input2 from "../../component/input/Input";
import Select from "../../component/input/Select";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_COMMUNE_URL, USER_SOURCE_URL, USER_TYPE_SIGNALEMENT_URL } from "../../config/RoutesUrl";
import useHttp from "../../hooks/useHttp";
import HeaderConnecte from "component/header/HeaderConnecte";
import KjosHeader from "component/KjosHeader";
import Footer from "component/footer/Footer";
import Rectangle from "component/rectangle/Rectangle";
import CheckRadio from "component/input/CheckRadio";
import InputDate from "component/input/InputDate";
import { PRE_PLAINTE_FAITS_PATH } from "config/PathUrl";
import KjosNextPage from "component/KjosNextPage";

export default function PrePlainteIdentite() {
  const {getData} = useHttp();
  const navigate = useNavigate();
  const location = useLocation();
  const [commune, setCommune] = useState([]);

  const handleForward = (e) => {
    e.preventDefault();
    const stateData = JSON.parse(location.state?.data);
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    const data2 = {
      ...data,
      ...stateData,
    };

    navigate(PRE_PLAINTE_FAITS_PATH, { state: { signalement_id: location?.state?.signalement_id, data: JSON.stringify(data2), nextPage: true } });
  };

  useEffect(() => {
    getData(USER_COMMUNE_URL).then((data) => {
      const com = data?.data?.map((item) => ({
        label: item.libelle,
        value: item.id,
        id: item.id,
      }));
      setCommune(com);
    });
  },[])

  return (
    <KjosNextPage>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
      >
                <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>

          <KjosHeader>Créer un signalement | ANSSI</KjosHeader>

          <HeaderConnecte />
          <form onSubmit={handleForward} style={{ width: "100%", height: "100%" }}>
          {location?.state?.signalement_id && (
              <Typography variant="body2" sx={{ textAlign: "center", background: "linear-gradient(95.08deg, rgba(33, 39, 89, 1) 0%, rgba(74, 144, 226, 1) 100%)", font: "800 24px sans-serif", textDecoration: "underline", "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent" }}>
                Signalement N°: {location?.state?.signalement_id}
              </Typography>
            )}

            <Stack direction={"column"} justifyContent={"center"} sx={{ height: "100%", width: "100%", px: { xs: 1, md: 8 }, py: 8 }} spacing={4}>
              <Typography variant="h4" sx={{ textAlign: "start", color: main_blue, fontWeight: "bold" }}>
                Identité
                <Typography variant="body2" sx={{ textAlign: "start", color: "black" }}>
                  Les champs suivis de (*) sont obligatoires.
                </Typography>
              </Typography>

              <Divider sx={{ width: "70%", borderBottomWidth: 1, color: "black", bgcolor: "black" }} />

              <Typography variant="h4" sx={{ textAlign: "start", color: main_blue, fontWeight: "bold" }}>
                Informations vous concernant :
                <Typography variant="body2" sx={{ textAlign: "start", color: "black" }}>
                  Seule la victime, ou son représentant légal le cas échéant, peut établir cette déclaration.{" "}
                </Typography>
              </Typography>

              <Stack direction={"column"} spacing={1} px={1}>
                <Typography variant="h6" sx={{ textAlign: "start", fontWeight: "bold" }}>
                  Vous déposez plainte en qualité de :{" "}
                </Typography>
                <Stack direction={"row"} spacing={1}>
                  <CheckRadio name="victime" required value="victime" /> <Typography variant="body1">victime</Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <CheckRadio name="victime" required value="sous_tutelle" /> <Typography variant="body1">Représentant légal d'une personne morale </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <CheckRadio name="victime" required value="mineure" /> <Typography variant="body1"> Représentant légal d'une personne physique (mineure, sous tutelle, ...)</Typography>
                </Stack>
              </Stack>

              {/* Grid */}
              <Stack direction={"column"} spacing={1}>
                <Typography variant="h6" sx={{ textAlign: "start", fontWeight: "bold" }}>
                  Vous déposez plainte en qualité de :{" "}
                </Typography>
                <Grid container spacing={2} px={2}>
                  <Grid item xs={12} md={6}>
                    <Select
                      label="Vous êtes*"
                      name="etat_civile"
                      options={[
                        { label: "Mr", value: "Mr" },
                        { label: "Mme", value: "Mme" },
                        { label: "Mlle", value: "Mlle" },
                      ]}
                      width="100%"
                      required
                    />{" "}
                    <br />
                    <Input label="Nom*" type="text" name="nom_naissance" required /> <br />
                    {/* <Input label="Nom d’époux(se)" type="text" name="nom_epoux" /> <br /> */}
                    <Input label="Prénom(s)*" type="text" name="prenom" required /> <br />
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2} sx={{ width: "100%" }}>
                      <InputDate label="Date de naissance*" name="date" max={new Date().toISOString().split("T")[0]} style={{ width: "100%" }} required />
                      <Select
                        label="Sexe*"
                        name="genre"
                        options={[
                          { label: "Homme", value: "M" },
                          { label: "Femme", value: "F" },
                        ]}
                      />{" "}
                      <br />
                    </Stack>
                    <br />
                    <Input label="Lieu de naissance*" type="text" name="lieu_naissance" required /> <br />
                    <Input label="Pays*" type="text" name="pays" required /> <br />
                  </Grid>

                  <Grid item xs={12} md={6}>
                      <Select label="Commune*" options={commune} width="100%" name="commune" required /> <br />
                    {/* <Input label="Commune*" type="text" name="commune" required /> <br /> */}
                    <Input label="Situation familiale*" type="text" name="situation_familiale" required /> <br />
                    <Input label="Nationalité*" type="text" name="nationalite" required /> <br />
                    <Input label="Profession*" type="text" name="profession" required /> <br />
                    <Rectangle
                      label="Adresse*"
                      content={
                        <Stack direction={{ xs: "column", md: "row" }} my={2} spacing={{ xs: 2, md: 1 }}>
                          <Input label="N°" type="text" name="numero_rue" required />
                          <Input label="Nom de la voie" type="text" name="nom_de_la_voie" required />
                        </Stack>
                      }
                    />{" "}
                    <br />
                  </Grid>
                </Grid>
              </Stack>

              <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
                <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)}>
                  Précédent
                </ButtonMui>

                <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                  Suivant
                </ButtonMui>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Box>
      <Footer fixed={false} />
    </KjosNextPage>
  );
}
