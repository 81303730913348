import { Box, Button as ButtonMui, Container, Grid, Stack, Typography } from "@mui/material";
import { bg_black, main_blue2 } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input from "../../component/input/Input2";
import Select from "../../component/input/Select";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { USER_SOURCE_URL, USER_TYPE_SIGNALEMENT_URL } from "../../config/RoutesUrl";
import useHttp from "../../hooks/useHttp";
import HeaderConnecte from "component/header/HeaderConnecte";
import KjosHeader from "component/KjosHeader";
import Footer from "component/footer/Footer";
import Rectangle from "component/rectangle/Rectangle";
import { PRE_PLAINTE_SERVICE_PATH } from "config/PathUrl";

const content = `
Ce service vous permet de déposer une déclaration pour des infractions aux biens, telles que les vols, les dégradations et les escroqueries, dont vous avez été victime, même si vous ne connaissez pas l'identité de l'auteur. L'objectif principal de cette procédure est de vous faire gagner du temps lorsque vous vous rendez à l'unité ou au service de votre choix pour y signer cette déclaration et qu'elle soit enregistrée comme une plainte. Il est crucial de conserver les preuves exploitables par les enquêteurs. Avez-vous pris connaissance des conditions d'utilisation de ce service et souhaitez-vous continuer ?

En cas d'urgence, n'oubliez pas d'appeler immédiatement le numéro ..........

Toutes les informations requises seront exclusivement utilisées pour le traitement informatisé de votre déclaration. Seuls les agents autorisés des unités de gendarmerie ou des services de police auront accès à ces données dans le seul but d'organiser un rendez-vous avec vous ou votre représentant légal pour la signature de la plainte. Selon la loi sur l'informatique, les fichiers et les libertés, vous avez le droit d'accéder à ces informations et de les rectifier, si nécessaire, en vous adressant à l'unité de gendarmerie ou au service de police où vous signerez votre plainte.
`;
export default function PrePlainteSignalement() {
  const navigate = useNavigate();
  const handleForward = (e) => {
    e.preventDefault();

    navigate(PRE_PLAINTE_SERVICE_PATH, { state: { nextPage: true } });
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
      >
        <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>
          <KjosHeader>Créer un signalement | ANSSI</KjosHeader>

          <HeaderConnecte />
          <form onSubmit={handleForward} style={{ width: "100%", height: "100%" }}>
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ height: "100%", width: "100%", px: { xs: 1, md: 8 }, py:8 }} spacing={4}>
              {location?.state?.signalement_id && (
                <Typography variant="body2" sx={{ textAlign: "center", background: "linear-gradient(95.08deg, rgba(33, 39, 89, 1) 0%, rgba(74, 144, 226, 1) 100%)", font: "800 24px sans-serif", textDecoration: "underline", "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent" }}>
                  Signalement N°: {location?.state?.signalement_id}
                </Typography>
              )}

              <Rectangle label="Note d'information" content={content} />

              <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-end", m: "auto" }} type="submit">
                Suivant
              </ButtonMui>
            </Stack>
          </form>
        </Stack>
      </Box>
      <Footer />
    </>
  );
}
