import { Avatar, Box, Divider, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import Logo from "../logo/Logo";
import Button from "../buttons/Button";
import { main_blue, main_gray } from "../../config/Color";
import FinanceIcon from "../buttons/icons/FinanceIcon";
import LogoGuinee from "../logo/LogoGuinee";
import LogoLogin from "../logo/LogoLogin";
import { Link, useNavigate } from "react-router-dom";
import { SIGNIN_PATH } from "../../config/PathUrl";
import { useDispatch } from "react-redux";
import { setAlert } from "store/SignalementReducer";
import useHttp from "hooks/useHttp";
import { useEffect, useState } from "react";
import { CMS_GET } from "config/RoutesUrl";
import getDefaultImage from "controller/getDefaultImage";
import { null_image } from "config/Image";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;
export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlenavigate = () => {
    dispatch(
      setAlert({
        open: true,
        message: "Veuillez vous connecter avant de continuer",
        severity: "info",
      })
    );

    navigate(SIGNIN_PATH, { state: { nextPage: true } });
  };

  const defaultSiteSlogan = (
    <>
      {/* Portail officiel de signalement <br />
      des contenus illicites de l’internet */}
    </>
  );

  const { getData } = useHttp(); // get data from server
  const [cms, setcms] = useState({});
  useEffect(() => {
    getData(CMS_GET).then((data) => {
      if (data?.error) {
        return;
      }
      setcms(data?.data);
    });
  }, []);

  return (
    <>
      <Box
        component="header"
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1100,
          p: 0,
          m: 0,
          bgcolor: "white",
        }}
      >
        <Stack direction="column" spacing={2}>
          {/* with Logo */}
          <Stack direction={{ xs: "column", lg: "row" }} justifyContent={"space-between"} alignItems={"center"} px={6} sx={{ bgcolor: main_blue, color: "white" }}>
            <Stack direction={{ xs: "column", lg: "row" }} alignItems={"center"}>
              <Link to={"/"}>
                <Logo width="5rem" logo_url={getDefaultImage(cms?.header?.logo_url, null_image)} />
              </Link>
              <Typography sx={{ fontSize: { xs: "0.7rem", md: "1rem", lg: "1.2rem" } }}>{cms?.header?.site_slogan ? <div dangerouslySetInnerHTML={{ __html: cms?.header?.site_slogan.replace(/<\/?p>/g, "") }}></div> : defaultSiteSlogan}</Typography>
            </Stack>

            <Stack direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
              <Link to={SIGNIN_PATH}>
                <LogoLogin />
              </Link>
              {/* 
              <Button
                height="3rem"
                width="9rem"
                title="Connexion"
                bgColor={main_blue}
                color="white"
                onClick={() => {
                  navigate(SIGNIN_PATH);
                }}
              /> */}
            </Stack>
          </Stack>

          {/* Divider */}
          {/* <Divider sx={{ bgcolor: "#e1e1e1", borderWidth: 1, borderStyle: "solid" }} /> */}

          {/* With button 3 */}
          <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} px={6}>
            {/* <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Button icon={<FinanceIcon />} title="Nouveau Signalement" onClick={() => navigate(SIGNIN_PATH, { state: { nextPage: true } })} />
              <Button title="Suivie de signalement" onClick={() => navigate(SIGNIN_PATH, { state: { nextPage: true } })} />
              <Button title="Pré-plainte" onClick={() => navigate(SIGNIN_PATH, { state: { nextPage: true } })}  />
            </Stack> */}

            <Link to={"/"}>
              <LogoGuinee />
            </Link>
          </Stack>

          {/* Divider */}
          <Divider sx={{ bgcolor: "#e1e1e1", borderBottomWidth: 2, borderStyle: "solid", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }} />
        </Stack>
      </Box>
    </>
  );
}
