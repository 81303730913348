import { Avatar, Box, Divider, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import Logo from "../logo/Logo";
import Button from "../buttons/Button";
import { main_blue } from "../../config/Color";
import FinanceIcon from "../buttons/icons/FinanceIcon";
import LogoGuinee from "../logo/LogoGuinee";
import { Link, useNavigate } from "react-router-dom";
import { SIGNIN_PATH } from "../../config/PathUrl";
import useHttp from "hooks/useHttp";
import { useEffect, useState } from "react";
import { CMS_GET } from "config/RoutesUrl";
import { null_image } from "config/Image";
import getDefaultImage from "controller/getDefaultImage";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;
export default function Header2() {
  const navigate = useNavigate()

  const defaultSiteSlogan = (
    <>
     {/*  Portail officiel de signalement <br />
      des contenus illicites de l’internet */}
    </>
  );

  const { getData } = useHttp(); // get data from server
  const [cms, setcms] = useState({});
  useEffect(() => {
    getData(CMS_GET).then((data) => {
      if (data?.error) {
        return;
      }
      setcms(data?.data);
    });
  }, []);;



  return (
    <>
      <Box
        component="header"
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
        }}
      >
        <Stack direction="column" spacing={2}>
          {/* with Logo */}
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={6}>
            <Stack direction={"row"} alignItems={"center"}>
              <Link to={"/"}>
              <Logo width="5rem" logo_url={getDefaultImage(cms?.header?.logo_url, null_image)} />
              </Link>
              <Typography sx={{ fontSize: { xs: "0.7rem", md: "1rem", lg: "1.2rem" } }}>{cms?.header?.site_slogan ? <div dangerouslySetInnerHTML={{ __html: cms?.header?.site_slogan.replace(/<\/?p>/g, '') }}></div> : defaultSiteSlogan}</Typography>
            </Stack>

          </Stack>

          {/* Divider */}
          <Divider sx={{ bgcolor: "#e1e1e1", borderWidth: 1, borderStyle: "solid" }} />
        </Stack>
      </Box>
    </>
  );
}
