import { IconButton, Stack, Typography } from "@mui/material";
import "./input.css";
import { RemoveRedEye } from "@mui/icons-material";
import { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Email } from "@mui/icons-material";
import { Phone } from "@mui/icons-material";


export default function InputDate(prop) {
  const { width = "100%", value, defaultValue, onChange, disabled, style, label, name, id, max, required } = prop;

  
  

  
  return (
    <>
      <div className="frame-1000002056-2" style={{ ...style}}>
        <input  type={"date"}  value={value} defaultValue={defaultValue} onChange={onChange} disabled={disabled} className={"_00-00-00-00-00-2"}  name={name} id={id} max={max} required={required} />
  
        <div className="frame-1000002030-2">
          <div className="email-ou-num-ro-de-t-l-phone-2"> {label} </div>
        </div>
      </div>

    </>
  );
}
