import "./foldericon.css";
export default function FolderIcon(props) {
  const { style } = props;

  return (
    <div className="iconly-bold-bookmark" style={{ ...style }}>
  <svg
    className="bookmark"
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5625 0.921631H7.41875C9.1125 0.921631 10.4812 1.41226 10.5 2.65945V9.61991C10.5 9.69786 10.475 9.77581 10.425 9.84459C10.3438 9.95463 10.2063 10.0372 10.0375 10.0739C9.875 10.1105 9.69375 10.0922 9.54375 10.028L5.49375 8.54237L1.4375 10.028C1.34438 10.0642 1.2375 10.0876 1.13125 10.0876C0.78125 10.0876 0.5 9.87668 0.5 9.61991V2.65945C0.5 1.41226 1.875 0.921631 3.5625 0.921631ZM3.1375 4.41561H7.84375C8.1125 4.41561 8.33125 4.25467 8.33125 4.05338C8.33125 3.85162 8.1125 3.69114 7.84375 3.69114H3.1375C2.86875 3.69114 2.65 3.85162 2.65 4.05338C2.65 4.25467 2.86875 4.41561 3.1375 4.41561Z"
      fill="white"
    />
  </svg>
</div>

  );
}
