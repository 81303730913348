import { Avatar, Box, Divider, IconButton, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import Logo from "../logo/Logo";
import Button from "../buttons/Button";
import { main_blue, main_gray } from "../../config/Color";
import FinanceIcon from "../buttons/icons/FinanceIcon";
import LogoGuineeWhite from "../logo/LogoGuineeWhite";
import { Link, useNavigate } from "react-router-dom";
import { SIGNIN_PATH } from "../../config/PathUrl";
import { useDispatch } from "react-redux";
import { setAlert } from "store/SignalementReducer";
import useHttp from "hooks/useHttp";
import { useEffect, useState } from "react";
import { CMS_GET } from "config/RoutesUrl";
import getDefaultImage from "controller/getDefaultImage";
import { null_image } from "config/Image";
import LogoLogin from "../logo/LogoLogin";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;
export default function HeaderAccueil() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlenavigate = () => {
    dispatch(
      setAlert({
        open: true,
        message: "Veuillez vous connecter avant de continuer",
        severity: "info",
      })
    );

    navigate(SIGNIN_PATH, { state: { nextPage: true } });
  };

  const defaultSiteSlogan = (
    <>
     {/*  Portail officiel de signalement <br />
      des contenus illicites de l’internet */}
    </>
  );

  const { getData } = useHttp(); // get data from server
  const [cms, setcms] = useState({});
  useEffect(() => {
    getData(CMS_GET).then((data) => {
      if (data?.error) {
        return;
      }
      setcms(data?.data);
    });
  }, []);

  return (
    <>
      <Box
        component="header"
        sx={{
          p: 0,
          mb: {sm: 0, md: 2},
          bgcolor: "white",
        }}
      >
        <Stack direction="column" spacing={2}>
          {/* with Logo */}
          <Stack direction={{ xs: "column", lg: "row" }} justifyContent={"space-between"} alignItems={"center"} px={6} sx={{ bgcolor: main_blue, color: "white" }}>
            <Stack direction={{ xs: "column", lg: "row" }} alignItems={"center"}>
              <Link to={"/"}>
                <Logo width="5rem" logo_url={getDefaultImage(cms?.header?.logo_url, null_image)} />
              </Link>
              <Typography sx={{ fontSize: { xs: "0.7rem", md: "1rem", lg: "1.2rem" } }}>{cms?.header?.site_slogan ? <div dangerouslySetInnerHTML={{ __html: cms?.header?.site_slogan.replace(/<\/?p>/g, '') }}></div> : defaultSiteSlogan}</Typography>
            </Stack>

            <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"center"}>
              {/* <Link to={"/"}>
                <LogoGuineeWhite />
              </Link> */}
              <Tooltip title="Connexion">
              <Link to={SIGNIN_PATH} className="text-decoration-none">
                {/* <LogoLogin /> */}
                <Typography sx={{ fontSize: { xs: "0.5rem", md: "0.6rem", lg: "0.8rem" }, color: "white" }}>Se connecter</Typography>
              </Link>
              </Tooltip>
            

              {/* <Button
                height="3rem"
                width="9rem"
                title="Connexion"
                bgColor={main_blue}
                color="white"
                onClick={() => {
                  navigate(SIGNIN_PATH);
                }}
              /> */}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
